@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
/* stylelint-disable-next-line scss/at-mixin-argumentless-call-parentheses */
@include mat-core();

$my-blue: (
  50: #079BE5,
  100: #079BE5,
  200: #079BE5,
  300: #079BE5,
  400: #079BE5,
  500: #079BE5,
  600: #079BE5,
  700: #079BE5,
  800: #079BE5,
  900: #079BE5,
  A100: #079BE5,
  A200: #079BE5,
  A400: #079BE5,
  A700: #079BE5,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat-palette($my-blue);
$candy-app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$candy-app-theme: mat-light-theme(
  (
    color: (
      primary: $candy-app-primary,
      accent: $candy-app-accent,
      warn: $candy-app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);
