// if you uncomment @tailwind base, then all of the <h*></h*> tags will lose their font size and weight
// @tailwind base;
@tailwind components;
@tailwind utilities;


@import 'app/app.component';
@import 'app/styles/material-mdc-migration';
@import '../node_modules/@ag-grid-community/core/dist/styles/ag-grid.css';
@import '../node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
// ag-theme-balham.css is needed for the AG Grid in Chrome Profile Wipe
// this theme can be eliminated when we migrate to device search actions:
@import '../node_modules/@ag-grid-community/core/dist/styles/ag-theme-balham.css';
@import '../node_modules/@cdw-ae/common-ui-components-bulk-update-action/tailwind-classes.scss';
@import '../node_modules/@cdw-ae/components-filterbar/tailwind-classes.scss';

$color-blue: #039AE5;

:root {
  --dialog-header: #039AE5;
  --primary-light: #ffffff;
}


html,
body {
  height: 100%;
  margin: 0;
  margin-bottom: 64px;
  overflow-y: hidden;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.spring {
  display: flex;
  flex: 1 1 auto;
}

.ait-error {
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
  mat-dialog-container {
    padding: 0;
  }
}

.ait-confirmation {
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
  mat-dialog-container {
    padding: 0;
  }
}

cdw-ae-export-wizard {
  h2.title {
    font-size: 20px;
    font-weight: 500;
  }
}

.mat-mdc-dialog-container .mdc-dialog__title{
  background: var(--dialog-header);
  color: var(--primary-light);
}

.generic-dialog,
.no-header-dialog {
  .mat-mdc-dialog-container {
    padding: 0;

    > * {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
    }

    .title-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: $color-blue;

      h3 {
        margin-bottom: 0;
      }

      button {
        mat-icon {
          color: white;
        }
      }
    }

    .mat-mdc-dialog-title {
      padding: 0 1em;
      margin: 0;
      color: white;
      background-color: $color-blue;
    }

    .mat-mdc-dialog-content {
      flex-grow: 1;
      min-height: 0 !important;
      max-height: none;
      padding: 20px;
      margin: 0;
    }

    .mat-mdc-dialog-actions {
      justify-content: flex-end;
      padding: 0.5em 1em;
      margin: 0;
    }
  }
}

.mat-mdc-snack-bar-container {
  background: transparent;
  box-shadow: none;
}

.h-100 {
  height: 100%;
}

.transparent .mat-mdc-dialog-container {
  background: rgba(0, 0, 0, 0%);
  box-shadow: none;
}

.text-align-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1rem;
}

.timepicker-overlay {
  z-index: 1000 !important;
}

.icon-header {
  .ag-icon {
    position: absolute;
    right: 0;
    top: 4px;
  }
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;

  &.text-center {
    display: inline-grid !important;
  }
}

.ag-header-row {
  .text-center .ag-header-cell-comp-wrapper{
    display: inline-grid;
  }
}

.ag-theme-alpine {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
