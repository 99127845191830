// TEMP WORKAROUND FOR ISSUE
//https://github.com/angular/components/issues/26094
@use "@angular/material" as mat;

.mat-mdc-card.mat-elevation-z1 {
  @include mat.elevation(1);
}

.mat-mdc-card.mat-elevation-z2 {
  @include mat.elevation(2);
}

.mat-mdc-card.mat-elevation-z6 {
  @include mat.elevation(6);
}

.mat-mdc-card.mat-elevation-z8 {
  @include mat.elevation(8);
}

.mat-mdc-card.mat-elevation-z10 {
  @include mat.elevation(10);
}
// END TEMP WORKAROUND


button > mat-icon {
  font-size:24px;
}

  .mat-mdc-unelevated-button>.mat-icon {
    font-size:24px !important;
    color: white;
  }
.mat-mdc-list-item .mat-icon {
    color: white;
  }

.icon-black {
  color: black !important;
}

.mat-mdc-button>.mat-icon {
  font-size:24px !important;
}


.mat-mdc-button>.mat-icon {
  font-size:24px !important;
}

.mat-mdc-button>.mat-icon,.mat-mdc-list-item>.mat-icon {
  font-size:24px !important;
}

.mat-mdc-raised-button>.mat-icon {
  font-size:24px !important;
}

body.theme-default .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-label-text-color: #FFFFFF;
}

body.theme-default .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-label-text-color: #FFFFFF;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  --mdc-checkbox-selected-checkmark-color: #FFFFFF;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  --mdc-dialog-supporting-text-color: black;
}

// prevents checkbox from cutting off in ag grid header
.ag-header-cell-comp-wrapper {
  overflow: unset;
}

// LSC-1992 - this is a hack that has the potential to cause problems
cdw-ag-grid-select-all-column-header .mat-mdc-checkbox {
  margin-left: -12px !important;
}

.mat-mdc-card-title {
  --mdc-typography-headline6-line-height: 1.4;
  --mdc-typography-headline6-font-weight: bold;
  --mat-card-title-text-weight: bold;
}

// LSC-1992 - this is a hack
.error-negative-margin {
  margin-top:-20px;
}

.mat-mdc-tab-body-wrapper {
  flex-grow: 1;
}

.mat-mdc-tab, .mat-mdc-tab-link {
  flex-grow: 0 !important;
  padding-right: 48px !important;
  padding-left: 48px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid lightgrey;
}

.mat-primary.mat-mdc-raised-button {
  --mdc-protected-button-label-text-color: white;
}

.mat-mdc-raised-button>.mat-icon, .mat-mdc-icon-button>.mat-icon {
  width: unset !important;
  height: unset !important;
}

.mat-toolbar h1 {
  --mat-toolbar-title-text-weight: 500;
  --mat-toolbar-title-text-size: 20px;
}

.font-size-14-important {
  font-size: 14px !important;
}

.pl-0-important {
  padding-left: 0 !important;
}

.pb-0-important {
  padding-bottom: 0 !important;
}

.pt-0-important {
  padding-top: 0 !important;
}

.pt-2-important {
  padding-top: 0.5rem !important;
}

.pr-6-important {
  padding-right: 1.5rem !important;
}

.px-6-important {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.mt-0-important {
  margin-top: 0 !important;
}

.mt--4-important {
  margin-top: -1rem !important;
}

.mat-mdc-button-base {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 400;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  --mdc-plain-tooltip-supporting-text-size: 10px;
}

.mat-mdc-paginator {
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-color: grey;
}

.mat-mdc-form-field-subscript-wrapper {
  --mat-form-field-subscript-text-size: 75%;
}


.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: white;
}

.justify-end-important {
  justify-content: end !important;
}

.mat-mdc-dialog-title {
  display: flex !important;
  align-items: center !important;
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif !important;
}

.form-field-white .mat-mdc-form-field-infix,
.form-field-white .mat-mdc-form-field-focus-overlay,
.form-field-white .mat-mdc-text-field-wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  background-color:white !important;
}

.form-field-white-dropdown .mat-mdc-text-field-wrapper,
.form-field-white-dropdown .mat-mdc-form-field-focus-overlay {
  padding-left: 0 !important;
  background-color: white !important;
}

.mdc-list-item__content {
  mat-icon {
    color: #FFFFFF;
  }
}


.text-gray-500-important {
  color: rgb(107 114 128) !important;
}

.py-2-important {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #45a163;
  --mdc-switch-selected-handle-color: #45a163;
  --mdc-switch-selected-hover-state-layer-color: #45a163;
  --mdc-switch-selected-pressed-state-layer-color: #45a163;
  --mdc-switch-selected-focus-handle-color: #2e7031;
  --mdc-switch-selected-hover-handle-color: #2e7031;
  --mdc-switch-selected-pressed-handle-color: #2e7031;
  --mdc-switch-selected-focus-track-color: #6dbb75;
  --mdc-switch-selected-hover-track-color: #6dbb75;
  --mdc-switch-selected-pressed-track-color: #6dbb75;
  --mdc-switch-selected-track-color: #6dbb75;
}

// Added to allow for select option panels to be wider than the original select control component
.fit-content.mat-mdc-select-panel {
  min-width: fit-content;
}
