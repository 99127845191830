
.background-error {
  color: #FFFFFF;
  background-color: #E45858 !important;
}

.background-blocked {
  color: #FFFFFF;
  background-color: #D10000 !important;
}

.background-pending {
  color: #FFFFFF;
  background-color: #88AF85 !important;
}

.background-not-sent {
  color: #FFFFFF;
  background-color: #525252 !important;
}
